import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDNpnr5O4H5K_Y5mMrCWC82MjTsZfx44i0',
  authDomain: 'skuddsikker-vest.firebaseapp.com',
  projectId: 'skuddsikker-vest',
  storageBucket: 'skuddsikker-vest.appspot.com',
  messagingSenderId: '714056459967',
  appId: '1:714056459967:web:d12c68f383b52ab502480a',
  measurementId: 'G-9W3VBNM784',
}
try {
  firebase.initializeApp(firebaseConfig)
} catch (err) {
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack)
  }
}

export default firebase
