/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import { useSelector } from 'react-redux'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import VideoActionButton from './../../components/VideoActionButton'
import VideoPlayer from '../../components/VideoPlayer'
import firebase from '../../config/firebaseConfig'

import {
  button1,
  button2,
  button3,
  button4,
  button5,
  button1Active,
  button2Active,
  button3Active,
  button4Active,
  button5Active,
} from '../../util/svgs'

export default function SinglePoem({ data: { poem } }) {

  const [loading, setLoading] = useState(false)
  const [like, setLike] = useState({
    visible: false,
    selected: null,
  })
  const [likeCount, setLikeCount] = useState({})
  const video = useSelector((state) => state.video)
  const cover = getSrc(poem.cover.asset)

  const likesRef = useRef();
  const mainRef = useRef();

  useEffect(() => {
    if (likesRef.current) {
      setTimeout(() => {
          likesRef.current.classList.add('animation-in')
      }, 50)
    }
    let vh = window.innerHeight * 0.01;
    mainRef.current.style.height=`${vh * 100}px`
  }, [video.ended])

  const videoJsOptions = {
    crossorigin: 'anonymous',
    autoplay: true,
    controls: false,
    videoType: 'normal',
    poster: cover,
    currentTime: 0,
    muted: true,
    videoId:poem.originalID,
    playsinline: true,
    html5: {
      nativeTextTracks: true,
    },
    sources: [
      {
        src: `https://videodelivery.net/${poem.originalID}/manifest/video.m3u8`,
      },
    ],
  }

  const db = firebase.firestore()
  const docRef = db.collection('videos').doc(poem.shortname)

  async function fetchDta() {
    const res = await docRef.get()
    return res.data()
  }

  const likeClickHandle = async (button) => {
    if (like.selected === null) {
      setLoading(true)
      setLike({
        visible: false,
        selected: button,
      })
      const data = await fetchDta()
      const selectedLike = data.likes[button]

      await docRef.update({
        likes: {
          ...data.likes,
          [button]: selectedLike + 1,
        },
      })
      const updatedData = await fetchDta()
      setLikeCount(updatedData.likes)
      setLike({
        visible: true,
        selected: button,
      })
      setLoading(false)
    }
  }

  return (
    <Layout>
      <SEO title={poem.title} />
      <main className="main max-w-md mx-auto" ref={mainRef}>
        {loading && (
          <div className="loader flex fixed top-0 left-0 w-full h-full z-50 justify-center items-center" />
        )}
        
        <div className="player-outer relative h-full overflow-hidden">
          <div className="player-outer__img h-full">
            <VideoPlayer {...videoJsOptions} />
          </div>
          {!video.ended && (
          <div className="fixed bottom-3 left-0 w-full">
            <div className="max-w-md mx-auto">
              <div className="pl-4">
                <VideoActionButton videoType="normal" />
              </div>
            </div>
            </div>
          )}
          {video.ended && (
            <div ref={likesRef} className="fixed top-0 bottom-0 left-0 right-0 px-4 py-5 mt-5 z-70">
              <div className="max-w-md mx-auto h-full flex flex-col justify-between">
              <button
                type="button"
                className="ml-auto mt-12 relative focus:outline-none like-button"
                onClick={() => likeClickHandle('darlige_greier_ass')}
              >
                {like.selected === 'darlige_greier_ass' ? (
                  <img src={button1Active} alt="" />
                ) : (
                  <img src={button1} alt="" />
                )}

                {like.visible && (
                  <span className="bg-brand border-black border-2 rounded-full h-10 p-1.5 flex justify-center items-center text-base text-black absolute -top-5 left-5 w-10 font-demi">
                    {likeCount.darlige_greier_ass}
                  </span>
                )}
              </button>
              <button
                type="button"
                className="-mt-16 text-left focus:outline-none like-button"
                onClick={() => likeClickHandle('faen_sa_bra')}
              >
                <div className="relative inline-block">
                  {like.selected === 'faen_sa_bra' ? (
                    <img src={button2Active} alt="" />
                  ) : (
                    <img src={button2} alt="" />
                  )}
                  {like.visible && (
                    <span className="bg-brand border-black border-2 rounded-full h-10 p-1.5 flex justify-center items-center text-base text-black absolute top-4 right-8 w-10 font-demi">
                      {likeCount.faen_sa_bra}
                    </span>
                  )}
                </div>
              </button>
              <button
                type="button"
                className="-mt-14 sm:mt-0 ml-auto relative focus:outline-none like-button"
                onClick={() => likeClickHandle('jeg_blir_gira')}
              >
                {like.selected === 'jeg_blir_gira' ? (
                  <img src={button3Active} alt="" />
                ) : (
                  <img src={button3} alt="" />
                )}
                {like.visible && (
                  <span className="bg-brand border-black border-2 rounded-full h-10 p-1.5 flex justify-center items-center text-base text-black font-demi absolute -top-1 right-2 w-10">
                    {likeCount.jeg_blir_gira}
                  </span>
                )}
              </button>
              <button
                type="button"
                className="-mt-1 text-left focus:outline-none like-button"
                onClick={() => likeClickHandle('trist_ass')}
              >
                <div className="relative inline-block">
                  {like.selected === 'trist_ass' ? (
                    <img src={button4Active} alt="" />
                  ) : (
                    <img src={button4} alt="" />
                  )}
                  {like.visible && (
                    <span className="bg-brand border-black border-2 rounded-full h-10 p-1.5 flex justify-center items-center text-base text-black font-demi absolute -top-2 right-2 w-10">
                      {likeCount.trist_ass}
                    </span>
                  )}
                </div>
              </button>
              <button
                type="button"
                className="-mt-20 sm:mt-0 ml-auto relative focus:outline-none like-button"
                onClick={() => likeClickHandle('hva_faen')}
              >
                {like.selected === 'hva_faen' ? (
                  <img src={button5Active} alt="" />
                ) : (
                  <img src={button5} alt="" />
                )}
                {like.visible && (
                  <span className="bg-brand border-black border-2 rounded-full h-10 p-1.5 flex justify-center items-center text-base text-black font-demi absolute -top-2 right-2 w-10">
                    {likeCount.hva_faen}
                  </span>
                )}
              </button>
              <div className="flex justify-center mt-2 remix-link-button">
                <Link
                  to={`/dikt/${poem.shortname}/remix`}
                  className="cursor-pointer font-bold text-4xl w-44 bg-brand text-black no-underline font-brand text-center py-2"
                >
                  LAG REMIX
                </Link>
              </div>
              </div>
              </div>
          )} 
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    poem: sanityDikt(id: { eq: $id }) {
      title
      cover {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      originalID
      shortname
    }
  }
`
